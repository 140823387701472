











































import { Vue, Component, Prop } from "vue-property-decorator";

class SessionClosingEditorControlProperties {
    finalizeEnabled: boolean = true;
    deleteEnabled: boolean = true;
}

export { SessionClosingEditorControlProperties };

@Component({})
export default class SessionClosingEditorControlView extends Vue {
    @Prop({ default: new SessionClosingEditorControlProperties() }) 
    public properties: SessionClosingEditorControlProperties;

    private clicked(button: string) {
        this.$emit('clicked', button);
    }
}
