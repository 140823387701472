






































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { SessionClosingInfoProperties } from "./SessionClosingInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SessionClosingCustomerNameHistoryProperties } from "./SessionClosingCustomerNameHistoryView.vue";
import { Options } from "autonumeric";
import MathService from "@/services/MathService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AutoNumericUtil from "@/utilities/AutoNumericUtil";
import CommonUtil from "@/utilities/CommonUtil";
import SessionClosingDetailService from "@/services/SessionClosingDetailService";

class SessionClosingDetailDialogProperties {
    visible: boolean = false;
    detail: any = {};
}

export { SessionClosingDetailDialogProperties };

@Component({
    data: () => ({
        saving: false
    })
})
export default class SessionClosingDetailDialogView extends Vue {
    @Prop({ default: new SessionClosingDetailDialogProperties() })
    private properties: SessionClosingDetailDialogProperties;
    @Prop() private sessionClosingInfo: SessionClosingInfoProperties;
	@Prop() private sessionClosingCustomerNameHistory: SessionClosingCustomerNameHistoryProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private sessionClosingDetailService = new SessionClosingDetailService();
    private mathService = new MathService();
    private inputQuantity: HTMLInputElement;
    private inputPrice: HTMLInputElement;
    private inputWeight: HTMLInputElement;
    private inputTotalWeight: HTMLInputElement;
    private inputTotalAmount: HTMLInputElement;

    private lastValue: any = {};

    public get detail() {
        return this.properties.detail;
    }

	public get liveSessionDetail() {
		return this.detail.liveSessionDetail;		
	}

	public get facebookComment() {
		return this.liveSessionDetail.facebookComment ?? {};
	}

	public get facebookAccount() {
		return this.liveSessionDetail.facebookAccount;
	}

	public get visibleComment() {
		const facebookCommentId = this.liveSessionDetail.facebookCommentId ?? 0;
		return facebookCommentId !== 0;
	}

	public get navigatorUrl() {
		const facebookAccountId = this.liveSessionDetail.facebookAccountId;
		return `/management/customers/navigator?facebookAccountId=${facebookAccountId}`;
	}

	public nameHistory() {
		this.sessionClosingCustomerNameHistory.facebookAccountId = this.liveSessionDetail.facebookAccountId;
		this.sessionClosingCustomerNameHistory.visible = true;
	}

    public mounted() {
        const dialog = <Vue>this.$refs.content; 

        if (dialog) {
            this.inputQuantity = dialog.$el.querySelector("#quantity");
            this.inputWeight = dialog.$el.querySelector("#weight");
            this.inputPrice = dialog.$el.querySelector("#price");
            this.inputTotalWeight = dialog.$el.querySelector("#total-weight");
            this.inputTotalAmount = dialog.$el.querySelector("#total-amount");

            this.inputQuantity.addEventListener("blur", this.calculate);
            this.inputWeight.addEventListener("blur", this.calculate);
            this.inputPrice.addEventListener("blur", this.calculate);
            this.inputQuantity.addEventListener("keydown", this.calculate);
            this.inputWeight.addEventListener("keydown", this.calculate);
            this.inputPrice.addEventListener("keydown", this.calculate);

            this.updateFormatter();
        }
    }

    public updateFormatter() {
        setTimeout(() => {
            const options1: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false
            };
            const options2 = CommonUtil.clone(options1);
            options2.decimalPlaces = 0;

            AutoNumericUtil.findOrNew(this.inputQuantity, null, options2)
                .set(this.detail.quantity ?? 0);
            AutoNumericUtil.findOrNew(this.inputWeight, null, options1)
                .set(this.detail.weight ?? 0);
            AutoNumericUtil.findOrNew(this.inputPrice, null, options1)
                .set(this.detail.price ?? 0);
            AutoNumericUtil.findOrNew(this.inputTotalWeight, null, options1)
                .set(this.detail.totalWeight ?? 0);
            AutoNumericUtil.findOrNew(this.inputTotalAmount, null, options1)
                .set(this.detail.totalAmount ?? 0);
        }, 150);
    }

    public async calculate(e: Event) {
        if (e.type === "keydown" && (<KeyboardEvent>e).key !== "Enter") {
            return;
        }

        try {
            const quantity = Number(this.detail.quantity ?? 0);
            const weight = Number(this.detail.weight ?? 0);
            const price = Number(this.detail.price ?? 0);

            if (this.lastValue.quantity != quantity ||
                this.lastValue.weight != weight ||
                this.lastValue.price != price) {
                    
                const totalWeight = await this.mathService.round(quantity * weight, 2);
                const totalAmount = await this.mathService.round(quantity * price, 2);
                AutoNumericUtil.set(this.inputTotalWeight, totalWeight);
                AutoNumericUtil.set(this.inputTotalAmount, totalAmount);
            }

            this.lastValue.quantity = quantity;
            this.lastValue.weight = weight;
            this.lastValue.price = price;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;
        try {
            const r = await this.sessionClosingDetailService.put(this.detail);
            this.properties.detail = r.data;
            await this.sessionClosingInfo.events.fire("load-detail");
            await this.sessionClosingInfo.events.fire("load-customer");
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public close() {
        if (this.$data.saving) {
            return;
        }
        
        this.properties.visible = false;
    }
}
