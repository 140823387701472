import AbstractService from "./AbstractService";
import Axios from "axios";

export default class SessionClosingLiveSessionService extends AbstractService {
    private controller = "session-closing-live-sessions";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public post(sessionClosingLiveSession: any, importLiveSessionDetails: boolean) {
        const d = { sessionClosingLiveSession, importLiveSessionDetails };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public delete(sessionClosingLiveSession: any) {
        const d = { sessionClosingLiveSession };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }
}