var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1600"},model:{value:(_vm.properties.visible),callback:function ($$v) {_vm.$set(_vm.properties, "visible", $$v)},expression:"properties.visible"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(_vm._s(_vm.title))]),_c('v-divider'),_c('div',{staticClass:"px-4 py-2"},[_c('v-select',{attrs:{"items":_vm.liveSessions,"label":_vm.$t('text.live-session'),"loading":_vm.loadingDropDowns,"item-text":"uniqueNo","item-value":"id","hide-details":""},on:{"input":function($event){_vm.selected = []; _vm.load()}},model:{value:(_vm.liveSessionId),callback:function ($$v) {_vm.liveSessionId=$$v},expression:"liveSessionId"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.details,"page":_vm.table.pageNo,"items-per-page":_vm.table.recordCount,"loading":_vm.table.loading,"item-key":"id","hide-default-footer":"","show-select":"","disable-sort":""},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td'),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.liveSessionNo),callback:function ($$v) {_vm.$set(_vm.filter, "liveSessionNo", $$v)},expression:"filter.liveSessionNo"}})],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.customer),callback:function ($$v) {_vm.$set(_vm.filter, "customer", $$v)},expression:"filter.customer"}})],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.psid),callback:function ($$v) {_vm.$set(_vm.filter, "psid", $$v)},expression:"filter.psid"}})],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.productName),callback:function ($$v) {_vm.$set(_vm.filter, "productName", $$v)},expression:"filter.productName"}})],1),_c('td',{attrs:{"colspan":headers.length - 4}})])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"no-underline inherit-color",attrs:{"href":_vm.navigatorUrl(item),"target":"_blank"}},[_vm._v(" "+_vm._s(item.customer)+" ")])]}},{key:"item.price",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.toFixed(2)))]}},{key:"item.weight",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.toFixed(2)))]}},{key:"item.totalWeight",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.toFixed(2)))]}},{key:"item.totalAmount",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.toFixed(2)))]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-divider'),_c('v-card-actions',[_c('v-pagination',{staticClass:"py-1",attrs:{"length":_vm.table.pageCount,"total-visible":"12"},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.table.pageNo),callback:function ($$v) {_vm.$set(_vm.table, "pageNo", $$v)},expression:"table.pageNo"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":function($event){return _vm.importAll()}}},[_vm._v(_vm._s(_vm.$t("text.import-all")))]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.importData()}}},[_vm._v(_vm._s(_vm.$t("text.import")))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t("text.close")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }