


















import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SessionClosingInfoProperties } from "./SessionClosingInfoView.vue";
import SessionClosingService from "@/services/SessionClosingService";
import SystemSettingService from "@/services/SystemSettingService";
import ExceptionUtil from "@/utilities/ExceptionUtil";

class SessionClosingNotifyProperties {
    visible: boolean = false;
    facebookAccountId: number = 0;
    customers: any[] = [];
    sendAll: boolean = false;
}

export { SessionClosingNotifyProperties };

@Component({
    data: () => ({
        message: "",
        saving: false
    })
})
export default class SessionClosingNotifyView extends Vue {
    @Prop({ default: new SessionClosingNotifyProperties() })
    private properties: SessionClosingNotifyProperties;
    @Prop() private sessionClosingInfo: SessionClosingInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private sessionClosingService = new SessionClosingService();
    private systemSettingService = new SystemSettingService();

    public created() {
        this.loadTemplate();
    }

    public async loadTemplate() {
        try {
            const r = await this.systemSettingService.get();
            this.$data.message = r.data.sessionClosing.notifyTemplate;
        } catch (e) {
            console.log(e);
        }
    }

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        var refresh = false;
        var closing = false;

        try {
            const sessionClosing = this.sessionClosingInfo.sessionClosing;
            const message = this.$data.message;

            if (!this.properties.sendAll) {
                const facebookAccountId = this.properties.facebookAccountId;

                await this.sessionClosingService.notify(sessionClosing.id, facebookAccountId, message);
                refresh = true; closing = true;
            } else {
                const customers: any[] = this.properties.customers;
                const unnotified = customers.filter(x => !x.notified);
                if (unnotified.length === 0) {
                    var m: any = this.$t("message.session-closing-all-notified");
                    throw new Error(m);
                }

                for (var i = 0; i < unnotified.length; i++) {
                    const facebookAccountId = unnotified[i].facebookAccountId;
                    await this.sessionClosingService.notify(sessionClosing.id, facebookAccountId, message);
                    refresh = true;
                }
                closing = true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }

        if (refresh) await this.sessionClosingInfo.events.fire("load-customer");
        if (closing) this.properties.visible = false;
    }

    public close() {
        this.properties.visible = false;
    }
}
