













































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SessionClosingInfoProperties } from './SessionClosingInfoView.vue';
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from '@/utilities/ResponseUtil';
import DateUtil from '@/utilities/DateUtil';
import SessionClosingLiveSessionService from "@/services/SessionClosingLiveSessionService";
import LiveSessionService from "@/services/LiveSessionService";

class SessionClosingLiveSessionDialogProperties {
    visible: boolean = false;
}

export { SessionClosingLiveSessionDialogProperties };

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            recordCounts: [10, 20, 30, 40, 50],
            loading: false,
        },
        saving: false,
        selected: [],
        liveSessions: []
    })
})
export default class SessionClosingLiveSessionDialogView extends Vue {
    @Prop({ default: new SessionClosingLiveSessionDialogProperties() })
    private properties: SessionClosingLiveSessionDialogProperties;
    @Prop() private sessionClosingInfo: SessionClosingInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private liveSessionService = new LiveSessionService();
    private sessionClosingLiveSessionService = new SessionClosingLiveSessionService();
    private dateTimeFormat: string = null;

    public get headers() {
        return [
            {
                text: this.$t("text.session-no"),
                value: "uniqueNo"
            },
            {
                text: this.$t("text.session-date"),
                value: "sessionDate"
            },
            {
                text: this.$t("text.started-date"),
                value: "startedDate"
            },
            {
                text: this.$t("text.ended-date"),
                value: "endedDate"
            }
        ];
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public created() {
        this.sessionClosingInfo.events.subscribe('alert-dialog-closed', this.alertDialogClosed);
        this.load();
    }

    public destroyed() {
        this.sessionClosingInfo.events.remove('alert-dialog-closed', this.alertDialogClosed);
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;
        try {
            const r = await this.liveSessionService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                status: "Ended"
            });
            this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);

            const liveSessions = r.data.liveSessions;
            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            this.$data.liveSessions = liveSessions.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
                this.properties.visible = false;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public async submit() {
        if (this.$data.saving) {
        } else if (this.$data.selected.length === 0) {
            var m: any = this.$t("message.live-session-nothing-selected");
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.visible = true;
        } else {
            this.$data.saving = true;
            try {
                const sessionClosing = this.sessionClosingInfo.sessionClosing;
                for (var i = 0; i < this.$data.selected.length; i++) {
                    var liveSession = this.$data.selected[i];
                    const sessionClosingLiveSession = {
                        sessionClosingId : sessionClosing.id,
                        liveSessionId : liveSession.id
                    };
                    await this.sessionClosingLiveSessionService.post(sessionClosingLiveSession, true);
                }

                await this.sessionClosingInfo.events.fire('load-live-session');
                await this.sessionClosingInfo.events.fire('load-detail');
                this.properties.visible = false;
            } catch (e) {
                if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                    var m = ExceptionUtil.getMessage(e);
                    AlertDialogProperties.error(this.alertDialog, null, m);
                    this.alertDialog.tag = { command: "live-session-dialog-error" };
                    this.alertDialog.visible = true;
                }
            } finally {
                this.$data.saving = false;
            }
        }
    }

    public close() {
        this.properties.visible = false;
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && tag.command === 'live-session-dialog-error') {
            await this.load();
        }
    }
}
