









































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { SessionClosingInfoProperties } from "./SessionClosingInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import StringUtil from "@/utilities/StringUtil";
import SessionClosingLiveSessionService from "@/services/SessionClosingLiveSessionService";
import SessionClosingDetailService from "@/services/SessionClosingDetailService";
import LiveSessionDetailService from "@/services/LiveSessionDetailService";
import FilterUtil from "@/utilities/FilterUtil";

class SessionClosingDetailImportProperties {
    visible: boolean = false;
}

export { SessionClosingDetailImportProperties };

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            recordCounts: [10, 20, 30, 40, 50],
            loading: false
        },
        filter: {
            liveSessionNo: "",
            customer: "",
			psid: "",
            productName: ""
        },
        loadingDropDowns: false,
        liveSessionId: -1,
        saving: false,
        selected: [],
        liveSessions: [],
        details: []
    })
})
export default class SessionClosingDetailImportView extends Vue {
    @Prop({ default: new SessionClosingDetailImportProperties() })
    private properties: SessionClosingDetailImportProperties;
    @Prop() private sessionClosingInfo: SessionClosingInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private sessionClosingLiveSessionService = new SessionClosingLiveSessionService();
    private sessionClosingDetailService = new SessionClosingDetailService();
    private liveSessionDetailService = new LiveSessionDetailService();
    private queueHandle: number = 0;

    public get title() {
        return this.$t("title.import-live-session-detail");
    }

    public get headers() {
        return [
            {
                text: this.$t("text.session-no"),
                value: "liveSession.uniqueNo",
                width: "8%"
            },
            {
                text: this.$t("text.customer"),
                value: "customer",
                width: "15%"
            },
			{
				text: this.$t("text.psid"),
				value: "facebookAccount.pageScopedId",
				width: "12%"
			},
            {
                text: this.$t("text.product-name"),
                value: "liveSessionProduct.name"
            },
            {
                text: this.$t("text.quantity"),
                value: "quantity",
                width: "8%"
            },
            {
                text: this.$t("text.price"),
                value: "price",
                width: "8%"
            },
            {
                text: this.$t("text.total-weight-g"),
                value: "totalWeight",
                width: "8%"
            },
            {
                text: this.$t("text.total-amount"),
                value: "totalAmount",
                width: "8%"
            }
        ];
    }

	public navigatorUrl(item: any) {
		const facebookAccountId = item.facebookAccountId;
		return `/management/customers/navigator?facebookAccountId=${facebookAccountId}`;
	}

    public created() {
        this.loadDropDowns();
        this.load();
    }

    public async loadDropDowns() {
        if (this.$data.loadingDropDowns) {
            return;
        }
        this.$data.loadingDropDowns = true;

        const sessionClosing = this.sessionClosingInfo.sessionClosing;
        try {
            const r = await this.sessionClosingLiveSessionService.get({
                sessionClosingId: sessionClosing.id,
                loadLiveSession: true
            });
            const liveSessions = [
                {
                    uniqueNo: "All",
                    id: -1
                }
            ];
            r.data.sessionClosingLiveSessions.forEach(e => {
                liveSessions.push(e.liveSession);
            });

            this.$data.liveSessions = liveSessions;
        } catch (e) {
            console.log(e);
        } finally {
            this.$data.loadingDropDowns = false;
        }
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load();
        }, delay);
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        const sessionClosing = this.sessionClosingInfo.sessionClosing;
        const liveSessionId = this.$data.liveSessionId === -1 ? null : this.$data.liveSessionId;
        try {
            const filter = this.$data.filter;
			const customer = filter.customer;

            const r = await this.liveSessionDetailService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                liveSessionId,
                liveSessionNo: StringUtil.enclose(filter.liveSessionNo, "%", "%"),
				pageScopedId: StringUtil.enclose(filter.psid, "%", "%"),
                liveSessionProductName: StringUtil.enclose(filter.productName, "%", "%"),
                appScopedId: FilterUtil.filterString(false, "-- CLOSED --", true),
                sessionClosingId: sessionClosing.id,
				searchCustomer: customer ? `customer: ${customer}` : null,
                loadLiveSession: true,
                loadLiveSessionProduct: true,
                loadFacebookComment: true,
				loadFacebookAccount: true
            });
            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const details: any[] = r.data.liveSessionDetails;

			details.forEach(e => {
				e.weight ??= 0;
				e.price ??= 0;
				e.totalWeight ??= 0;
				e.totalAmount ??= 0;

				const f = e.facebookAccount;
				const n = f.fullName ?? "";
				const u = f.uniqueNo ?? null;

				e.customer = u !== null ? `${n} [${u}]` : n;
			});

            this.$data.details = details.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public async importAll() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const sessionClosing = this.sessionClosingInfo.sessionClosing;
            const liveSessionId = this.$data.liveSessionId === -1 ? null : this.$data.liveSessionId;

            await this.sessionClosingDetailService.importAll(sessionClosing.id, liveSessionId);
            await this.sessionClosingInfo.events.fire("load-detail");
            await this.sessionClosingInfo.events.fire('load-customer');
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async importData() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const sessionClosing = this.sessionClosingInfo.sessionClosing;
            const selected = this.$data.selected;
            if (selected.length !== 0) {
                const liveSessionDetailIds = [];
                selected.forEach(e => liveSessionDetailIds.push(e.id));

                await this.sessionClosingDetailService.importData(sessionClosing.id, liveSessionDetailIds);
                await this.sessionClosingInfo.events.fire("load-detail");
                await this.sessionClosingInfo.events.fire('load-customer');
                await this.load();
            } else {
                var m: any = this.$t("message.nothing-selected");
                AlertDialogProperties.info(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public close() {
        this.properties.visible = false;
    }
}
