import AbstractService from "./AbstractService";
import Axios from "axios";

export default class LiveSessionDetailService extends AbstractService {
    private controller = "live-session-details";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public current(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/current`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public ordered(liveSessionProductId: number) {
        var q = { liveSessionProductId };
        const url = this.makeUrlWithQueries(`${this.controller}/ordered`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public save(d: any) {
        const url = this.makeUrl(`${this.controller}/save`);
        return Axios.post<any>(url, d, this.makeConfig());
    }
    
    public replace(d: any) {
        const url = this.makeUrl(`${this.controller}/replace`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public decrease(d: any) {
        const url = this.makeUrl(`${this.controller}/decrease`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(liveSessionDetail: any) {
        const d = { liveSessionDetail };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }
}