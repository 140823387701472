
































































































import { Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SessionClosingEditorControlProperties } from "./SessionClosingEditorControlView.vue";
import { SessionClosingLiveSessionDialogProperties } from "./SessionClosingLiveSessionDialogView.vue";
import { SessionClosingDetailImportProperties } from "./SessionClosingDetailImportView.vue";
import { SessionClosingDetailDialogProperties } from "./SessionClosingDetailDialogView.vue";
import { SessionClosingCustomerNameHistoryProperties } from "./SessionClosingCustomerNameHistoryView.vue";
import SessionClosingEditorControlView from "./SessionClosingEditorControlView.vue";
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import EventManager from "@/utilities/EventManager";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from '@/utilities/ResponseUtil';
import SessionClosingService from "@/services/SessionClosingService";
import { SessionClosingNotifyProperties } from "./SessionClosingNotifyView.vue";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const SessionClosingMainView = () => import("./SessionClosingMainView.vue");
const SessionClosingLiveSessionView = () => import("./SessionClosingLiveSessionView.vue");
const SessionClosingDetailView = () => import("./SessionClosingDetailView.vue");
const SessionClosingCustomerView = () => import("./SessionClosingCustomerView.vue");
const SessionClosingLiveSessionDialogView = () => import("./SessionClosingLiveSessionDialogView.vue");
const SessionClosingDetailImportView = () => import("./SessionClosingDetailImportView.vue");
const SessionClosingDetailDialogView = () => import("./SessionClosingDetailDialogView.vue");
const SessionClosingCustomerNameHistoryView = () => import("./SessionClosingCustomerNameHistoryView.vue");
const SessionClosingNotifyView = () => import("./SessionClosingNotifyView.vue");

class SessionClosingInfoProperties {
    sessionClosing: any = {};
    dateTimeFormat: string = null;
    events = new EventManager();
}

export { SessionClosingInfoProperties };

@Component({
    components: {
		SessionClosingEditorControlView,
        AlertDialogView,
		SessionClosingMainView,
        SessionClosingLiveSessionView,
        SessionClosingDetailView,
        SessionClosingCustomerView,
		SessionClosingLiveSessionDialogView,
		SessionClosingDetailImportView,
		SessionClosingDetailDialogView,
		SessionClosingCustomerNameHistoryView,
		SessionClosingNotifyView
    },
    data: () => ({
        tab: null,
        saving: false
    })
})
export default class SessionClosingInfoView extends Mixins(InfoViewMixin) {
    private properties = new SessionClosingInfoProperties();
    private alertDialog = new AlertDialogProperties();
    private editorControl = new SessionClosingEditorControlProperties();
    private sessionClosingLiveSessionDialog = new SessionClosingLiveSessionDialogProperties();
    private sessionClosingDetailDialog = new SessionClosingDetailDialogProperties();
    private sessionClosingDetailImport = new SessionClosingDetailImportProperties();
	private sessionClosingCustomerNameHistory = new SessionClosingCustomerNameHistoryProperties();
    private sessionClosingNotify = new SessionClosingNotifyProperties();
    private sessionClosingService = new SessionClosingService();

	public get title() {
		const tmp1 = this.$t('title.session-closing');
		const tmp2 = this.$t('title.create');
		const tmp3 = this.$t('title.modify');

		return `${tmp1} - ${this.isCreate() ? tmp2 : tmp3}`;
	}

    public get sessionClosing() {
        return this.properties.sessionClosing;
    }

    public refreshEditorControl() {
        if (this.sessionClosing.status === 'Open') {
            this.editorControl.finalizeEnabled = true;
            this.editorControl.deleteEnabled = true;
        } else if (this.sessionClosing.status === "Finalized") {
            this.editorControl.finalizeEnabled = false;
            this.editorControl.deleteEnabled = false;
        } else {
            this.editorControl.finalizeEnabled = false;
            this.editorControl.deleteEnabled = false;
        }
    }

    public async mounted() {
        this.mode = this.$route.params.mode;

        if (!this.checkMode() || this.isView()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else if (this.isCreate()) {
            await this.create(); //create new
        } else {
            await this.load();
        }
    }

    public async load() {
        try {
            var id = this.$route.query.id;
            const r = await this.sessionClosingService.get({ id });
            if (r.data.sessionClosings.length === 0) {
                throw new Error(this.$t("message.record-not-found").toString());
            }
            const sessionClosing = r.data.sessionClosings[0];

            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.properties.sessionClosing = sessionClosing;
            this.refreshEditorControl();

            await this.properties.events.fire("loaded");
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async create() {
        if (this.$data.saving) {
            return;
        }

        this.$data.saving = true;
        try {
            const r = await this.sessionClosingService.post(this.sessionClosing);

            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.properties.sessionClosing = r.data;
            
            const sessionClosingId = this.sessionClosing.id;
            await this.$router.replace("/management/session-closings/modify?id=" + sessionClosingId);
            this.mode = "modify";

            this.refreshEditorControl();
            await this.properties.events.fire("loaded");
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async finalize() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const r = await this.sessionClosingService.finalize(this.sessionClosing.id);
            
            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.properties.sessionClosing = r.data;

            this.refreshEditorControl();
            await this.properties.events.fire("loaded");
        } catch (e) {
             if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }
  
    public async remove() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.sessionClosingService.delete(this.sessionClosing);
            AlertDialogProperties.deleted(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "record-deleted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public removeDialog() {
        AlertDialogProperties.delete(this.alertDialog, null);
        this.alertDialog.tag = { command: "remove" };
        this.alertDialog.visible = true;
    }

    public async control(button: string) {
        if (button === "create") {
            await this.create();
        } else if (button === 'finalize') {
            await this.finalize();
        } else if (button === "delete") {
            this.removeDialog();
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "remove" && button === this.$t("text.yes")) {
                await this.remove();
            }
        }

        await this.properties.events.fire("alert-dialog-clicked", button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && (tag.command === "invalid-mode" || tag.command === "load-error" || tag.command === "record-deleted")) {
            await this.$router.push("/management/session-closings");
        }

        await this.properties.events.fire("alert-dialog-closed");
    }
}
