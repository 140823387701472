import AbstractService from "./AbstractService";
import Axios from "axios";

export default class SessionClosingService extends AbstractService {
    private controller = "session-closings";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public customer(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/customer`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public notify(sessionClosingId: number, recipientFacebookAccountId: number, message: string) {
        const d = { sessionClosingId, recipientFacebookAccountId, message };
        const url = this.makeUrl(`${this.controller}/notify`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public post(sessionClosing: any) {
        const d = { sessionClosing };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public finalize(id: number) {
        const d = { id };
        const url = this.makeUrl(`${this.controller}/finalize`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(sessionClosing: any) {
        const d = { sessionClosing };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }
}